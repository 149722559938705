import React from 'react';

import { Hero } from '../../components/Hero';
import { UserStats } from '../../components/UserStats';
import { ProductDetail } from '../../components/ProductDetail';
import { HowTo } from '../../components/HowTo';
import { Problem } from '../../components/Problem';
import { Pricing } from '../../components/Pricing';
import { ContactUs } from '../../components/ContactUs';
import { Footer } from '../../components/Footer';

export default class MainPage
  extends React.Component<{}> {
    render() {
      return (
        <div className="page">
          <Hero />
          <UserStats />
          <ProductDetail />
          <HowTo />
          <Problem />
          <Pricing />
          <ContactUs />
          <Footer />
        </div>
      );
    }
  }