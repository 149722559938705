import React from 'react';
import './style.css';

export class ContactUs
  extends React.Component {
    render() {
      return (
        <div className="contact-us">
          <div className="contact-us__container">
            <h1 className="contact-us__title">เชื่อมต่อกับระบบบัญชีของธุรกิจคุณ</h1>
            <p className="contact-us__description">
              Billbox สามารถเชื่อมต่อกับระบบบัญชีของธุรกิจ ผ่าน API เพื่อใช้ในการส่งบิลผ่าน LINE และ Email<br />
              รวมไปถึง การชำระเงินด้วยระบบ Instant Pay เพื่อกระทบยอดอัตโนมัติ (Auto Reconcile) แจ้งเตือนเข้าระบบบัญชีของคุณได้ทันที
            </p>
            <div className="button">
              <a
                id="contact-link--contact-session"
                className="link"
                href="https://docs.google.com/forms/d/1lSBk9xjdXxYrrXhtrRX4JTRljedls6ZHRZhXI6xWxFM"
                rel="noopener noreferrer"
              >
                ติดต่อเรา
              </a>
            </div>
          </div>
        </div>
      );
    }
  }