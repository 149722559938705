import React from 'react';
import './style.css';

export class UserStats
  extends React.Component {
    render() {
      return (
        <div className="user-stat">
          <div className="user-stat__container">
            <div className="stat-box">
              <h1 className="box-title">240 ผู้ใช้งาน</h1>
              <p className="box-sub">ที่ใช้ระบบของเราวางบิล / รับวางบิล</p>
            </div>
            <div className="stat-box">
              <h1 className="box-title">23,391 รายการ</h1>
              <p className="box-sub">ส่งบิลไปแล้ว มูลค่ากว่า 543 ล้านบาท</p>
            </div>
            <div className="stat-box">
              <h1 className="box-title">1,256 รายการ</h1>
              <p className="box-sub">รับวางบิล มูลค่ากว่า 116 ล้านบาท</p>
            </div>
          </div>
        </div>
      );
    }
  }