import React from 'react';
import financial from '../../assets/financial.svg';
import management from '../../assets/management.svg';
import customer from '../../assets/customer.svg';
import './style.css';

export class Problem
  extends React.Component {
    render() {
      return (
        <div className="problem">
          <div className="problem__container">
            <h1 className="problem__title">ธุรกิจของคุณกำลังเป็นแบบนี้อยู่ไหม ?</h1>
            <div className="problem__content">
              <div className="financial">
                <img src={financial} alt=""/>
                <h1 className="special-title">ฝ่ายการเงิน</h1>
                <ul className="item-list">
                  <li>ปวดหัวกับหารเตรียมบิล พับซองให้ลูกค้าทีละคน</li>
                  <li>ส่งไปแล้วไม่รู้ว่าเป้นอย่างไร ถึงลูกค้าหรือเปล่า</li>
                  <li>ลูกค้าแจ้งสลิปมาหลายช่องทาง กระทบยอดลำบาก</li>
                  <li>เอกสารที่เกี่ยวข้องกับบิลกระจัดกระจาย หาไม่เจอ</li>
                  <li>จัดการเอกสารรับวางบิลลำบาก เอกสารตกหล่น</li>
                </ul>
              </div>
              <div className="management">
                <img src={management} alt=""/>
                <h1 className="special-title">ผู้บริหาร</h1>
                <ul className="item-list">
                  <li>จะดูภาพรวมกระแสเงินสด ก็ต้องรอรายงานจากแผนกอื่น</li>
                  <li>อนุมัติรายจ่ายลำบาก เอกสารตีกันไปมาวุ่นวาย</li>
                  <li>ต้นทุนในการส่งบิลแต่ละใบสูง</li>
                </ul>
              </div>
              <div className="customer">
                <img src={customer} alt=""/>
                <h1 className="special-title">ลูกค้า และ Supplier</h1>
                <ul className="item-list">
                  <li>ลูกค้าทำบิลหายมาขอใหม่เรื่อย ๆ</li>
                  <li>ลูกค้าจ่ายแล้ว แต่ไม่ค่อยแจ้งสลิป</li>
                  <li>Supplier อยู่ไกล วางบิลลำบาก วางบิลไม่ทัน</li>
                  <li>Supplier โทรตามสถานะบิลที่วางไปกันแบบสายไหม้</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }