import React from 'react';
import check from '../../assets/check.svg';
import './style.css';

export class HowTo
  extends React.Component {
    render() {
      return (
        <div className="how-to">
          <div className="how-to__container">
            <div className="how-to__content">
              <div className="how-to__video">
                <div className="responsive-video">
                  <iframe
                    title="upload-excel"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/kXl0XmE2etI?rel=0&amp;fs=0&amp;showinfo=0"
                    frameBorder="0"
                    allowFullScreen
                  >
                  </iframe>
                </div>
              </div>
              <div className="how-to__description upload-excel">
                <h1 className="how-to__title">แปลง Excel เป็นบิลสวย ๆ<br/>ส่งพร้อมกันพันใบใน 3 คลิก</h1>
                <table className="how-to__table">
                  <tbody>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>ทำงานร่วมกับไฟล์ Excel ได้ทุกเวอร์ชัน</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>ระบบ Maker/Checker แยกคนสร้าง กับคนอนุมัติออกจากกัน</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>แสดง Promptpay QR ให้ลูกค้าสแกนจ่ายผ่านแอปธนาคาร</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>แสดง Barcode ให้ลูกค้าชำระที่จุดรับชำระเงินที่รองรับ</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>แนบไฟล์ที่เกี่ยวข้องไปพร้อมกับบิลได้</p></td>
                    </tr>
                    <tr>
                      <td colSpan={2}><a id="register-link--upload-excel" className="register-link" href="https://register.billme.co.th" target="_blank" rel="noopener noreferrer">สมัครใช้งาน</a></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="how-to__content">
              <div className="how-to__video">
                <div className="responsive-video">
                  <iframe
                      title="line-oa"
                      src="https://www.youtube.com/embed/6jzOeWiLm4Q?rel=0&amp;fs=0&amp;showinfo=0"
                      frameBorder="0"
                      allowFullScreen
                    >
                  </iframe>
                </div>
              </div>
              <div className="how-to__description line-oa">
              <h1 className="how-to__title">ส่งบิลเข้า LINE ของลูกค้าด้วย<br/>LINE OA หรือ Email<br/>ไม่ต้องโหลดแอป</h1>
                <table className="how-to__table">
                  <tbody>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>ส่งบิลหาลูกค้าผ่าน Billme LINE OA</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>ส่งบิลลูกค้าผ่าน Email</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>ติดตามสถานะของบิลได้ ส่งถึงไหม เปิดอ่านรึยัง ดูได้หมด (Email)</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td>
                        <p>ใช้ LINE OA หรือ Email ของธุรกิจตัวเอง <span className="premium-tag">Premium</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                      <a id="register-link--line-oa" className="register-link" href="https://register.billme.co.th" target="_blank" rel="noopener noreferrer">สมัครใช้งาน</a>
                      <a id="contact-link--line-oa" className="contact-link" href="https://lin.ee/3h1Zv1QTq" target="_blank" rel="noopener noreferrer">สอบถาม Premium Service</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="how-to__content">
              <div className="how-to__video">
                <div className="responsive-video">
                  <iframe
                    title="instant-pay"
                    src="https://www.youtube.com/embed/kRUc2fYcqzg?rel=0&amp;fs=0&amp;showinfo=0"
                    frameBorder="0"
                    allowFullScreen
                  >
                  </iframe>
                </div>
              </div>
              <div className="how-to__description instant-pay">
              <h1 className="how-to__title">Instant Pay จ่ายปุ๊บ รู้ปั๊บ<br/>ส่งใบเสร็จได้เลยทันที <span className="premium-tag">Premium</span></h1>
                <table className="how-to__table">
                  <tbody>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>กระทบยอดให้อัตโนมัติ (Auto Reconcile) ทันทีที่ลูกค้าชำระเงินเข้ามา</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>แจ้งเตือนทันทีที่มีลูกค้าชำระเงินเข้ามา</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>เก็บประวัติการชำระเงินที่ลูกค้าชำระเข้ามาทุกรายการ</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>รองรับ SCB, KBank, Krungsri</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>รองรับการชำระผ่าน QR Payment, Deeplink, Credit Card</p></td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <a id="register-link--instant-pay" className="register-link" href="https://register.billme.co.th" target="_blank" rel="noopener noreferrer">สมัครใช้งาน</a>
                        <a id="contact-link--instant-pay" className="contact-link" href="https://lin.ee/3h1Zv1QTq" target="_blank" rel="noopener noreferrer">สอบถาม Premium Service</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="how-to__content">
              <div className="how-to__video">
                <div className="responsive-video">
                  <iframe
                    title="online-bill"
                    src="https://www.youtube.com/embed/r554Y030r9c?rel=0&amp;fs=0&amp;showinfo=0"
                    frameBorder="0"
                    allowFullScreen
                  >
                  </iframe>
                </div>
              </div>
              <div className="how-to__description online-bill">
              <h1 className="how-to__title">รับวางบิลออนไลน์<br/>ไม่เจอหน้ากัน ก็ยังวางบิลได้<br/>อนุมัติที่ไหนก็ได้ ง่ายนิดเดียว</h1>
                <table className="how-to__table">
                  <tbody>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>ลิงก์สำหรับให้ Supplier กรอกข้อมูลเพื่อวางบิลออนไลน์</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>แจ้งเตือนเมื่อมีรายการวางบิลใหม่เข้ามา</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>กำหนดสิทธิ์ในการอนุมัติรายการรับวางบิลได้</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>รวบรวมไฟล์เอกสาร และข้อมูลที่เกี่ยวข้องไว้ในที่เดียวกัน</p></td>
                    </tr>
                    <tr>
                      <td colSpan={2}><a id="register-link--online-bill" className="register-link" href="https://register.billme.co.th" target="_blank" rel="noopener noreferrer">สมัครใช้งาน</a></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }