import React from 'react';
import billmeLogo from '../../assets/billme-logo-blue.png';
import './style.css';

export class Footer
  extends React.Component {
    render() {
      return (
        <div className="footer">
          <div className="footer__container">
            <img className="footer__logo" src={billmeLogo} alt="" />
            <div className="footer__content">
              <div className="billme-info">
                <p>
                  Billme คือ กลุ่มคนที่ตั้งใจที่จะนำเทคโนโลยีมาประกอบกับธุรกิจเพื่อให้ ธุรกิจสามารถพัฒนาได้
                  โดยทีมของเราโฟกัสที่ ‘บิล’ เพราะเอกสาร เป็นสิ่งที่ทำให้ธุรกิจเสียเวลา
                  ทีมของเราต้องการที่จะทำระบบที่ทำให้ลูกค้า สามารถใช้ได้อย่างวางใจ
                  เรามีความตั้งใจในการพัฒนาเพื่อให้ผู้ใช้งานระบบของเรา
                  สามารถโฟกัสที่ธุรกิจหลักของตัวเองได้อย่างเต็มที่ โดยมีพวกเราเป็นกำลังเสริม
                  ที่พร้อมจะวิ่งไปกับผู้ใช้
                </p>
              </div>
              <div className="contact-billme">
                <p>Facebook: <span>fb.com/billme.co.th</span></p><br /><br />
                <p>โทร: <span>081 - 649 - 5939</span></p><br /><br />
                <p>อีเมล: <span>contact@billme.co.th</span></p><br /><br />
                <p>สถานที่: <span>129 อาคาร BIG C0-Working Space <br /><b>ถ.พระราม 9 แขวงห้วยขวาง เขตห้วยขวาง</b><br /><b>กรุงเทพมหานคร 10310</b></span></p>
              </div>
              <div className="menu">
                <ul>
                  <a id="register-link--footer" href="https://register.billme.co.th"><li>สมัครใช้งานฟรี</li></a>
                  <a id="login-link--footer" href="https://billbox.billme.co.th"><li>เข้าสู่ระบบ</li></a>
                  <a id="medium-link--footer" href="https://medium.com/billme-co-th"><li>บทความ</li></a>
                  <a id="contact-link--footer" href="https://lin.ee/3h1Zv1QTq"><li>ติดต่อเรา</li></a>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }