import * as React from "react";
import dropdown from '../../assets/dropdown.svg';
import { SelectorProps, SelectorState } from "./type";

import "./style.css";

export default class Selector extends React.Component<SelectorProps, SelectorState> {
  constructor(props: SelectorProps) {
    super(props);

    this.state = {
      isFocus: false
    };
  }

  componentDidMount() {
    document.addEventListener('click', event => this.handleClickEvent(event));
  }

  render() {
    const { id, name, translator, selected, options, onSelectOption } = this.props;
    const filteredOptions = options.filter(value => value !== selected);

    return (
      <div id={id} className="selector" >
        <div className="selector__display" onClick={() => this.handleToggleSelector()}>
          <p className="selector--selected">
            {translator ? translator[selected] : selected}
          </p>
          <img className="selector__indicator" src={dropdown} alt="" />
        </div>
        <div className={`selector__options ${this.state.isFocus ? "show" : ""}`}>
          {this.renderOptions(id, name, filteredOptions, translator, onSelectOption)}
        </div>
      </div>
    );
  }

  renderOptions(
    id: string,
    name: string,
    options: string[],
    translator: {[key: string]: any},
    onSelectOption: (name: string, value: string) => void
  ) {
    return options.map((option, index) => {
      return (
        <div
          id={`${id}--${index}`}
          key={`${id}--${index}`}
          className="selector__option"
          onClick={() => {
            onSelectOption(name, option);
            this.handleToggleSelector();
          }}
        >
          {translator ? translator[option] : option}
        </div>
      );
    });
  }

  handleToggleSelector() {
    this.setState({ isFocus: this.state.isFocus ? false : true })
  }

  handleClickEvent(event: MouseEvent) {
    const element = (event.srcElement as Element);

    if (this.state.isFocus && !this.isParentElement(element, this.props.id)) {
      this.setState({isFocus: false});
    }
  }

  isParentElement(element: Element, id: string) {
    while (element) {
      if (element.id && element.id.indexOf(id) > -1) {
        return true;
      }

      element = element.parentElement!;
    }

    return false;
  }
}