import React from 'react';
import check from '../../assets/check.svg';
import greenCheck from '../../assets/green-check.svg';
import Selector from '../Selector';
import './style.css';

export type PricingState = {
  windowWidth: number;
  package: string;
}

export class Pricing
  extends React.Component<{}, PricingState> {
    constructor(props: {}) {
      super(props);

      this.state = {
        windowWidth: window.innerWidth,
        package: 'free'
      };
    }

    componentDidMount() {
      window.addEventListener('resize', () => this.setState({windowWidth: window.innerWidth}));
    }

    render() {
      return (
        <div className="pricing">
          <div className="pricing__container">
            <h1 className="pricing__title">ค่าบริการของเรา</h1>
            {
              this.state.windowWidth < 972 ?
              <div className="pricing__select">
                <Selector
                  id="package-select"
                  name="package"
                  selected={this.state.package}
                  options={['free', 'standard', 'premium']}
                  translator={{free: 'แพคเกจ นักขาย', standard: 'แพคเกจ นักการตลาด', premium: 'แพคเกจ CEO'}}
                  onSelectOption={(name, value) => this.setState({package: value})}
                />
              </div> :
              null
            }
            {this.state.windowWidth > 971 ? this.renderNormalElement() : this.renderSelectedElement(this.state.package)}
          </div>
        </div>
      );
    }

    renderSelectedElement(packageName: string) {
      switch (packageName) {
        case 'free':
          return (
            <div className="pricing__content">
              <div className="pricing__card">
                <p className="card__tag">นักขาย</p>
                <h1 className="card__title">ฟรี</h1>
                <p className="card__description">เหมาะสำหรับ Freelance หรือร้านขายของออนไลน์ ที่ต้องการวางบิลลูกค้า และติดตามสถานะการณ์ชำระเงิน</p>
                <table className="card__table">
                  <tbody>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>1 ธุรกิจ</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>1 ผู้ดูแล</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>500 บิล / เดือน</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p><span>Promptpay QR Code</span></p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p><span>Barcode สำหรับชำระเงิน</span></p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p><span>วางบิลผ่าน Billme Line OA</span></p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>สร้างบิลด้วย Excel ของระบบ Billbox</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>ดาวน์โหลดรายงาน Excel</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>จัดการรับวางบิล</p></td>
                    </tr>
                  </tbody>
                </table>
                <div className="card__action">
                  <a id="register-link--pricing-card" className="link" href="https://register.billme.co.th" rel="noopener noreferrer">สมัครใช้งาน</a>
                </div>
              </div>
            </div>
          );
        case 'standard':
          return (
            <div className="pricing__content">
              <div className="pricing__card">
                <p className="card__tag marketing-style">นักการตลาด</p>
                <h1 className="card__title marketing-text">415 บาท<span> / เดือน</span></h1>
                <p className="card__mark marketing-text">* ชำระเป็นรายปี จำนวนรวม 4,980 บาท / ปี </p>
                <p className="card__description">เหมาะสำหรับธุรกิจขนาดกลางที่เริ่มมีสาขา ต้องวางบิลลูกค้า จำนวนมาก ๆ ในแต่ละรอบ มีการรับชำระเงินจากหลายช่องทาง</p>
                <table className="card__table">
                  <tbody>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>ทุกฟังก์ชันใน <span>นักขาย</span></p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>ไม่จำกัดจำนวนธุรกิจ</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>ไม่จำกัดจำนวนผู้ดูแล</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p>1,000 บิล / เดือน</p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p><span>วางบิลผ่าน LINE OA ด้วย Brand ของคุณ</span></p></td>
                    </tr>
                    <tr>
                      <td><img src={check} alt="" /></td>
                      <td><p><span>วางบิลผ่าน Email ด้วย Brand ของคุณ</span></p></td>
                    </tr>
                  </tbody>
                </table>
                <div className="card__action">
                  <a id="contact-link--pricing-card--marketing" className="link" href="https://lin.ee/3h1Zv1QTq" rel="noopener noreferrer">ติดต่อเรา</a>
                </div>
              </div>
            </div>
          );
        case 'premium':
          return (
            <div className="pricing__content">
              <div className="pricing__card">
                <p className="card__tag ceo-style">CEO</p>
                <h1 className="card__title ceo-text">1,250 บาท<span> / เดือน</span></h1>
                <p className="card__mark ceo-text">* ชำระเป็นรายปี จำนวนรวม 15,000 บาท / ปี </p>
                <p className="card__description">เหมาะสำหรับธุรกิจขนาดใหญ่ ที่มีหลายสาขาต้องการเชื่อมต่อกับ Payment Gateway เพื่อเก็บเงินลูกค้า และกระทบยอดอัตโนมัติ</p>
                <table className="card__table">
                  <tbody>
                    <tr>
                      <td><img src={greenCheck} alt="" /></td>
                      <td><p>ทุกฟังก์ชันใน <span className="marketing-text">นักการตลาด</span></p></td>
                    </tr>
                    <tr>
                      <td><img src={greenCheck} alt="" /></td>
                      <td><p>ไม่จำกัดจำนวนธุรกิจ</p></td>
                    </tr>
                    <tr>
                      <td><img src={greenCheck} alt="" /></td>
                      <td><p>ไม่จำกัดจำนวนผู้ดูแล</p></td>
                    </tr>
                    <tr>
                      <td><img src={greenCheck} alt="" /></td>
                      <td><p>3,000 บิล / เดือน</p></td>
                    </tr>
                    <tr>
                      <td><img src={greenCheck} alt="" /></td>
                      <td><p><span>Instant Payment 1 บัญชีธนาคาร</span></p></td>
                    </tr>
                    <tr>
                      <td><img src={greenCheck} alt="" /></td>
                      <td><p><span>กระทบยอด และออกใบเสร็จอัตโนมัติ</span></p></td>
                    </tr>
                    <tr>
                      <td><img src={greenCheck} alt="" /></td>
                      <td><p>หน้ารายการบิลค้างชำระ ให้ลูกค้าของคุณเข้ามาตรวจสอบได้</p></td>
                    </tr>
                    <tr>
                      <td><img src={greenCheck} alt="" /></td>
                      <td><p>ปรับแต่งหน้าตาเอกสาร PDF ได้ 2 รูปแบบ</p></td>
                    </tr>
                  </tbody>
                </table>
                <div className="card__action">
                  <a id="contact-link--pricing-card--ceo" className="link" href="https://lin.ee/3h1Zv1QTq" rel="noopener noreferrer">ติดต่อเรา</a>
                </div>
              </div>
            </div>
          );
        default: return null;
      }
    }

    renderNormalElement() {
      return (
        <div className="pricing__content">
          <div className="pricing__card">
            <p className="card__tag">นักขาย</p>
            <h1 className="card__title">ฟรี</h1>
            <p className="card__description">เหมาะสำหรับ Freelance หรือร้านขายของออนไลน์ ที่ต้องการวางบิลลูกค้า และติดตามสถานะการณ์ชำระเงิน</p>
            <table className="card__table">
              <tbody>
                <tr>
                  <td><img src={check} alt="" /></td>
                  <td><p>1 ธุรกิจ</p></td>
                </tr>
                <tr>
                  <td><img src={check} alt="" /></td>
                  <td><p>1 ผู้ดูแล</p></td>
                </tr>
                <tr>
                  <td><img src={check} alt="" /></td>
                  <td><p>500 บิล / เดือน</p></td>
                </tr>
                <tr>
                  <td><img src={check} alt="" /></td>
                  <td><p><span>Promptpay QR Code</span></p></td>
                </tr>
                <tr>
                  <td><img src={check} alt="" /></td>
                  <td><p><span>Barcode สำหรับชำระเงิน</span></p></td>
                </tr>
                <tr>
                  <td><img src={check} alt="" /></td>
                  <td><p><span>วางบิลผ่าน Billme Line OA</span></p></td>
                </tr>
                <tr>
                  <td><img src={check} alt="" /></td>
                  <td><p>สร้างบิลด้วย Excel ของระบบ Billbox</p></td>
                </tr>
                <tr>
                  <td><img src={check} alt="" /></td>
                  <td><p>ดาวน์โหลดรายงาน Excel</p></td>
                </tr>
                <tr>
                  <td><img src={check} alt="" /></td>
                  <td><p>จัดการรับวางบิล</p></td>
                </tr>
              </tbody>
            </table>
            <div className="card__action">
              <a id="register-link--pricing-card" className="link" href="https://register.billme.co.th" rel="noopener noreferrer">สมัครใช้งาน</a>
            </div>
          </div>
          <div className="pricing__card">
            <p className="card__tag marketing-style">นักการตลาด</p>
            <h1 className="card__title marketing-text">415 บาท<span> / เดือน</span></h1>
            <p className="card__mark marketing-text">* ชำระเป็นรายปี จำนวนรวม 4,980 บาท / ปี </p>
            <p className="card__description">เหมาะสำหรับธุรกิจขนาดกลางที่เริ่มมีสาขา ต้องวางบิลลูกค้า จำนวนมาก ๆ ในแต่ละรอบ มีการรับชำระเงินจากหลายช่องทาง</p>
            <table className="card__table">
              <tbody>
                <tr>
                  <td><img src={check} alt="" /></td>
                  <td><p>ทุกฟังก์ชันใน <span>นักขาย</span></p></td>
                </tr>
                <tr>
                  <td><img src={check} alt="" /></td>
                  <td><p>ไม่จำกัดจำนวนธุรกิจ</p></td>
                </tr>
                <tr>
                  <td><img src={check} alt="" /></td>
                  <td><p>ไม่จำกัดจำนวนผู้ดูแล</p></td>
                </tr>
                <tr>
                  <td><img src={check} alt="" /></td>
                  <td><p>1,000 บิล / เดือน</p></td>
                </tr>
                <tr>
                  <td><img src={check} alt="" /></td>
                  <td><p><span>วางบิลผ่าน LINE OA ด้วย Brand ของคุณ</span></p></td>
                </tr>
                <tr>
                  <td><img src={check} alt="" /></td>
                  <td><p><span>วางบิลผ่าน Email ด้วย Brand ของคุณ</span></p></td>
                </tr>
              </tbody>
            </table>
            <div className="card__action">
              <a id="contact-link--pricing-card--marketing" className="link" href="https://lin.ee/3h1Zv1QTq" rel="noopener noreferrer">ติดต่อเรา</a>
            </div>
          </div>
          <div className="pricing__card">
            <p className="card__tag ceo-style">CEO</p>
            <h1 className="card__title ceo-text">1,250 บาท<span> / เดือน</span></h1>
            <p className="card__mark ceo-text">* ชำระเป็นรายปี จำนวนรวม 15,000 บาท / ปี </p>
            <p className="card__description">เหมาะสำหรับธุรกิจขนาดใหญ่ ที่มีหลายสาขาต้องการเชื่อมต่อกับ Payment Gateway เพื่อเก็บเงินลูกค้า และกระทบยอดอัตโนมัติ</p>
            <table className="card__table">
              <tbody>
                <tr>
                  <td><img src={greenCheck} alt="" /></td>
                  <td><p>ทุกฟังก์ชันใน <span className="marketing-text">นักการตลาด</span></p></td>
                </tr>
                <tr>
                  <td><img src={greenCheck} alt="" /></td>
                  <td><p>ไม่จำกัดจำนวนธุรกิจ</p></td>
                </tr>
                <tr>
                  <td><img src={greenCheck} alt="" /></td>
                  <td><p>ไม่จำกัดจำนวนผู้ดูแล</p></td>
                </tr>
                <tr>
                  <td><img src={greenCheck} alt="" /></td>
                  <td><p>3,000 บิล / เดือน</p></td>
                </tr>
                <tr>
                  <td><img src={greenCheck} alt="" /></td>
                  <td><p><span>Instant Payment 1 บัญชีธนาคาร</span></p></td>
                </tr>
                <tr>
                  <td><img src={greenCheck} alt="" /></td>
                  <td><p><span>กระทบยอด และออกใบเสร็จอัตโนมัติ</span></p></td>
                </tr>
                <tr>
                  <td><img src={greenCheck} alt="" /></td>
                  <td><p>หน้ารายการบิลค้างชำระ ให้ลูกค้าของคุณเข้ามาตรวจสอบได้</p></td>
                </tr>
                <tr>
                  <td><img src={greenCheck} alt="" /></td>
                  <td><p>ปรับแต่งหน้าตาเอกสาร PDF ได้ 2 รูปแบบ</p></td>
                </tr>
              </tbody>
            </table>
            <div className="card__action">
              <a id="contact-link--pricing-card--ceo" className="link" href="https://lin.ee/3h1Zv1QTq" rel="noopener noreferrer">ติดต่อเรา</a>
            </div>
          </div>
        </div>
      );
    }
  }