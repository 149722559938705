import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import MainPage from "./Main";

export default class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
