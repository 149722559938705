import React from 'react';
import image from '../../assets/chilling.svg';
import './style.css';

export class ProductDetail
  extends React.Component {
    render() {
      return (
        <div className="product-detail">
          <div className="product-detail__container">
            <h1 className="product-detail__title">วางบิลเก็บเงินลูกค้า เป็นเรื่องง่าย ๆ</h1>
            <div className="product-detail__content">
              <div className="product-detail__image">
                <img className="image" src={image} alt="" />
              </div>
              <table className="product-detail__table">
                <tbody>
                  <tr>
                    <td><p className="big-text">1</p></td>
                    <td><p className="normal-text">อัปโหลด Excel สร้างบิลพร้อมกันใน 3 คลิก</p></td>
                  </tr>
                  <tr>
                    <td><p className="big-text">2</p></td>
                    <td><p className="normal-text">ส่งบิลเข้า LINE หรือ Email ของลูกค้า</p></td>
                  </tr>
                  <tr>
                    <td><p className="big-text">3</p></td>
                    <td><p className="normal-text">แจ้งเตือนเมื่อลูกค้าชำระเงินเข้ามา</p></td>
                  </tr>
                  <tr>
                    <td><p className="big-text">4</p></td>
                    <td><p className="normal-text">กระทบยอดอัตโนมัติ ออกใบเสร็จได้ทันที</p></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    }
  }