import React from 'react';
import { Link } from 'react-router-dom';
import billmeLogo from '../../assets/billme-logo.png';
import lineLogo from '../../assets/line-logo.png';
import whiteWave from '../../assets/back-wave.svg';
import blueWave from '../../assets/wave.svg';
import dashboard from '../../assets/dashboard.svg';
import bill from '../../assets/bill.svg';
import './style.css';

export class Hero
  extends React.Component {
    render() {
      return (
        <div className="hero">
          <div className="hero__container">
            <div className="hero__header">
              <img id="billme-logo" className="billme-logo" src={billmeLogo} alt="Billme" />
              <div className="hero__menu">
                <Link id="service-link" className="hero-link" to="#services" children="บริการของเรา" />
                <Link id="pricing-link" className="hero-link" to="#pricing" children="ค่าบริการ" />
                <Link id="contact-link" className="hero-link" to="#contact" children="ติดต่อเรา" />
                <Link id="billbox-link" className="hero-link" to="https://billbox.billme.co.th" children="เข้าสู่ระบบ" target="_blank" rel="noopener noreferrer" />
                <Link id="register-link" className="hero-link--button" to="https://register.billme.co.th" children="สมัครใช้งานฟรี" target="_blank" rel="noopener noreferrer" />
              </div>
            </div>
            <div className="hero__content">
              <h1 className="hero__title">
                วางบิลผ่าน<img className="line-logo" src={lineLogo} alt="" />และอีเมล <br />
                เก็บเงินเร็ว ติดตามง่าย <br />
                สำหรับธุรกิจบริการ
              </h1>
              <p className="hero__description">
                สร้างบิลง่าย ส่งได้ไวขึ้น ไม่ต้องเทพบัญชีก็ใช้เป็น <br />
                เก็บเงินได้เร็วขึ้น ติดตามสถานะการชำระเงินได้ง่าย <br />
                ไม่ต้องโหลดโปรแกรม ใช้ผ่าน Web Browser ได้เลย
              </p>
              <Link id="register-link--2" className="hero-link--button in-content" to="https://register.billme.co.th" children="สมัครใช้งานฟรี" target="_blank" rel="noopener noreferrer" />
            </div>
          </div>
          <div className="hero__background-image">
            <img className="back-wave" src={whiteWave} alt="" />
            <img className="dashboard" src={dashboard} alt="" />
            <img className="bill" src={bill} alt="" />
            <img className="front-wave" src={blueWave} alt="" />
          </div>
        </div>
      );
    }
  }